import _Vue from 'vue'
import { TranslateResult } from 'vue-i18n'

type FlitsMessage = string | TranslateResult

export interface FlitsInterface {
  items: FlitsItem[]
  addMultiple(items: FlitsItem[] | boolean): void
  addSingle(item: FlitsItem | FlitsMessage, ttl: number): FlitsItem
  remove(item: FlitsItem): void
  success(message: FlitsMessage, link?: string): void
  error(message: FlitsMessage, link?: string): void
  info(message: FlitsMessage, link?: string): void
}

export interface FlitsItem {
  id?: string
  level: string
  message: FlitsMessage
  link: string | null
}

class Flits {
  items: FlitsItem[]

  constructor() {
    this.items = []

    if (_state && _state.flits) {
      this.addMultiple(_state.flits)
    }
  }

  addMultiple(items: FlitsItem[] | boolean): void {
    if (items === false) {
      return
    }

    items = items as FlitsItem[]

    if (!Array.isArray(items)) {
      items = [items]
    }

    items.forEach((item, key) => {
      setTimeout(() => {
        this.addSingle(item)
      }, key * 300 + 50)
    })
  }

  addSingle(item: FlitsItem | string, ttl = 8_000): FlitsItem {
    if (typeof item === 'string') {
      item = { level: 'error', message: item } as FlitsItem
    }

    item.id = this.getUUID()
    this.items.push(item)

    setTimeout(() => {
      this.remove(item as FlitsItem)
    }, ttl)

    return item
  }

  getUUID() {
    /* tslint:disable */
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
    /* tslint:enable */
  }

  remove(item: FlitsItem): void {
    const index = this.items.findIndex((i) => {
      return i.id === item.id
    })

    this.items.splice(index, 1)
  }

  onClick(item: FlitsItem) {
    this.remove(item)
  }

  success(message: string, link: string = null): void {
    this.addSingle({
      level: 'success',
      message,
      link,
    })
  }

  error(message: string, link: string = null): void {
    this.addSingle({
      level: 'error',
      message,
      link,
    })
  }

  info(message: string, link: string = null): void {
    this.addSingle({
      level: 'info',
      message,
      link,
    })
  }
}

const flits: FlitsInterface = new Flits()

export default {
  install(Vue: typeof _Vue, options: any = {}) {
    Object.defineProperty(Vue.prototype, '$flits', { value: flits })

    Vue.mixin({
      data: () => {
        return {
          flits: flits.items,
        }
      },
    })
  },
}

export { flits }
