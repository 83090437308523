import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '../locale/en.json'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en',
  messages: { en: messages },
})

const loadedLocales = ['en']

const setLocale = (locale: string) => {
  i18n.locale = locale
  document.querySelector('html').setAttribute('lang', locale)

  return locale
}

const changeLocale = (locale: string, customMessages: any = {}) => {
  if (i18n.locale === locale) {
    i18n.mergeLocaleMessage(locale, customMessages)
    return Promise.resolve(setLocale(locale))
  }

  if (loadedLocales.includes(locale)) {
    i18n.mergeLocaleMessage(locale, customMessages)
    return Promise.resolve(setLocale(locale))
  }

  return import(/* webpackChunkName: "lang-[request]" */ `../locale/${locale}.json`)
    .then((msgs) => {
      i18n.setLocaleMessage(locale, msgs.default)
      i18n.mergeLocaleMessage(locale, customMessages)
      loadedLocales.push(locale)

      return setLocale(locale)
    })
}

export default i18n

export { i18n, changeLocale }
