export default [
  {
    path: '/',
    name: 'splash',
    component: () => import('@views/app/splash/index.vue'),
  },
  {
    path: '/rooms',
    name: 'rooms',
    component: () => import('@views/app/rooms/index.vue'),
  },
  {
    path: '/rooms/:hash',
    name: 'room',
    component: () => import('@views/app/room/index.vue'),
  },
  {
    path: '/organizations/:hash',
    name: 'organization',
    component: () => import('@views/app/organization/index.vue'),
  },
  {
    path: '/game',
    name: 'game',
    component: () => import('@views/app/game/index.vue'),
    children: [
      {
        path: '',
        name: 'board',
        component: () => import('@views/app/game/views/board/index.vue'),
      },
      {
        path: 'results',
        name: 'results',
        component: () => import('@views/app/game/views/facilitator/results/index.vue'),
      },
      {
        path: 'result-check',
        name: 'result-check',
        component: () => import('@views/app/game/views/facilitator/result-check/index.vue'),
      },
      {
        path: 'confirm-result-check',
        name: 'confirm-result-check',
        component: () => import('@views/app/game/views/team/confirm-result-check/index.vue'),
      },
      {
        path: 'card/:hash/allocate',
        name: 'allocate-points',
        component: () => import('@views/app/game/views/team/allocate-points/index.vue'),
      },
    ],
  },
]
