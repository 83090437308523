import vFlits from '@components/v-flits/index.vue'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  components: {
    'v-flits': vFlits,
  },
})
export default class App extends Vue {}
