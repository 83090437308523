import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class PromiseButton extends Vue {
  [key: string]: any

  @Prop({ type: Boolean, default: false }) loading: boolean

  private time: number = 1000
  private isLoading: boolean = false
  private isSuccess: boolean = false
  private hasError: boolean = false
  private minLoadDuration: number = 1000

  private get listeners(): any {
    return {
      ...this.$listeners,
      click: this.handleClick,
    }
  }

  private get loadingState(): any {
    if (this.loading) {
      return true
    }

    return this.isLoading
  }

  private get computedClass() {
    if (this.isSuccess) {
      return 'success'
    }

    if (this.hasError) {
      return 'error'
    }

    if (this.loadingState) {
      return 'loading'
    }
  }

  private async handleClick(event: any) {
    if (this.isLoading) {
      return
    }

    try {
      this.isLoading = true
      await Promise.all([this.awaitMinLoadTime(), (this.$listeners as any).click(event)])
      this.resetDelayed('isSuccess')
    } catch (error) {
      this.resetDelayed('hasError')
    } finally {
      this.isLoading = false
    }
  }

  private awaitMinLoadTime() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, this.minLoadDuration)
    })
  }

  private resetDelayed(property: string) {
    if (this.$options.propsData.hasOwnProperty('loading')) {
      return
    }

    this[property] = true
    setTimeout(() => {
      this[property] = false
    }, this.time)
  }
}
