import Vue from 'vue'
import vIcon from '../components/v-icon/index.vue'
import vLoader from '../components/v-loader/index.vue'
import vPromiseButton from '../components/v-promise-button/index.vue'
import vResetButton from '../components/v-reset-button/index.vue'

Vue.component('v-icon', vIcon)
Vue.component('v-loader', vLoader)
Vue.component('v-promise-button', vPromiseButton)
Vue.component('v-reset-button', vResetButton)
