import { DirectiveOptions } from 'vue'

class LongPress {
  el: any
  node: any
  timeout: any = 0

  constructor(el: HTMLElement, node: any) {
    this.node = node

    el.style.userSelect = 'none'
    el.style['user-select' as any] = 'none'
    el.style['-webkit-user-select' as any] = 'none'

    const downEvents: string[] = ['touchstart', 'mousedown']
    const upEvents: string[] = ['touchend', 'mouseup']

    downEvents.forEach((event) => {
      el.addEventListener(event, this.hold.bind(this))
    })

    upEvents.forEach((event) => {
      document.addEventListener(event, this.release.bind(this))
    })
  }

  private hold(event: Event) {
    this.timeout = setTimeout(() => {
      this.node.value.call(this, event)
    }, 1000)

    event.stopPropagation()
    event.preventDefault()
  }

  private release() {
    clearTimeout(this.timeout)
  }
}

const directive: DirectiveOptions = {
  inserted(el: Element, node: any) {
    const d = new LongPress(el as HTMLElement, node)
  },
}

export default directive
