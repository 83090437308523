import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  props: {
    name: {
      required: true,
      type: String,
    },
  },
})
export default class Icon extends Vue {}
