import echo from '@plugins/echo'
import i18n from '@plugins/i18n'
import Logger, { LoggerInterface } from '@shared/error-logger'
import router from '@shared/router'
import '@ts/shared/components'
import '@ts/shared/directives'
import '@ts/shared/plugins'
import quadralityApp from '@views/app/index.vue'
import Vue from 'vue'

Vue.use(echo)

if (typeof _state === 'undefined') {
  (window as any)._state = {}
}

class Application {
  private logger: LoggerInterface

  constructor() {
    this.requireIcons()
    this.initLogger()
    this.init()
  }

  private init() {
    Vue.prototype.$bus = new Vue()

    const app = new Vue({
      router,
      i18n,
      components: {
        'v-app': quadralityApp,
      },
      render(h) {
        return h('v-app')
      },
    })

    app.$mount(document.getElementById('quadrality-app'))
  }

  private initLogger() {
    this.logger = new Logger()

    Vue.config.errorHandler = (err) => {
      this.logger.addErrorRecord(err)
      if (DEBUG) {
        throw err
      }
    }
  }

  private requireIcons() {
    const div = document.createElement('div')
    const body = document.body
    const cssString =
      'border: 0; clip: rect(0 0 0 0); height: 0; overflow: hidden; padding: 0; position: absolute; width: 0;'

    div.style.cssText = cssString
    div.innerHTML = require('!!html-loader!../img/icons.svg')
    body.insertBefore(div, body.childNodes[0])
  }
}

export default new Application()
